<style lang="scss">
#f-detail {
  .close {
    display: none;
  }
  .log {
    font-size: 12px;
    overflow-y: scroll;
    position: absolute;
    height: 77%;
    width: 94%;

    table th {
      position: -webkit-sticky; // this is for all Safari (Desktop & iOS), not for Chrome
      position: sticky;
      top: 0;
      z-index: 1; // any positive value, layer order is global
      background: #fff; // any bg-color to overlap
    }
  }
  .refund-btn {
    margin-left: 53%;
  }
  .list-unstyled {
    li {
      label {
        font-size: 14px;
      }
      .detail {
        font-weight: bold;
        margin-left: 5px;
      }
    }
  }
  .sub-header {
    font-size: 16px;
    font-weight: 500;
  }
  .img-outlet-logo {
    width: 90px;
    height: 60px;
  }
  .modal-body {
    padding-top: 0;
  }
  .refund-service {
    display: inline-flex;
    float: right;
  }
  .custom-file-upload {
    padding: 6px 12px;
    cursor: pointer;
    background: #1bc5bd;
    color: white;
    border-radius: 3px;
  }
}
</style>
<template>
  <b-modal
    id="f-detail"
    no-close-on-backdrop
    centered
    size="lg"
    v-model="showModal"
    :title="is_edit ? 'Update ORI' : 'Create ORI'"
  >
    <div class="row">
      <div class="col-6" style="padding: 10px;">
        <div class="form-group">
          <label for="">ORI Number <span class="text-danger">*</span></label>
          <input
            type="text"
            name=""
            class="form-control"
            id=""
            v-model="serviceori.ori_number"
            :class="errors['ori_number'] ? 'border border-danger' : ''"
          />
          <span v-if="errors['ori_number']" class="text-danger">{{
            errors["ori_number"][0]
          }}</span>
        </div>
      </div>
      <div class="col-6" style="padding: 10px;">
        <div class="form-group mr-10">
          <label for="">Requires Photograph</label>
          <span class="switch">
            <input
              type="checkbox"
              v-model="serviceori.requires_photograph"
              class="switch"
              id="requires_photograph"
            />
            <label for="requires_photograph"></label>
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="">Profession <span class="text-danger">*</span></label>
          <input
            type="text"
            v-model="serviceori.profession"
            name=""
            class="form-control"
            id=""
            :class="errors['profession'] ? 'border border-danger' : ''"
          />
          <span v-if="errors['profession']" class="text-danger">{{
            errors["profession"][0]
          }}</span>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="">Service <span class="text-danger">*</span></label>

          <select
            class="form-control"
            id="exampleFormControlSelect1"
            v-model="serviceori.service_code"
          >
            <option value="">Select Service</option>
            <option
              v-for="(item, index) in getServiceCodeData.data"
              :key="index"
              :value="item.id"
            >
              {{ item.service_name }}
            </option>
          </select>
          <span v-if="errors['service_code']" class="text-danger">{{
            errors["service_code"][0]
          }}</span>
        </div>
      </div>
    </div>
    <div class="row respo-row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="">Actual Rate <span class="text-danger">*</span></label>
          <input
            type="number"
            v-model="serviceori.our_rate"
            name=""
            class="form-control"
            id=""
            min="0"
            :class="errors['our_rate'] ? 'border border-danger' : ''"
          />
        </div>
      </div>
      <!-- <div class="col-md-2">
        <div class="form-group">
          <label for="">Default Rate <span class="text-danger">*</span></label>
          <input
            type="number"
            v-model="serviceori.default_rate"
            name=""
            class="form-control"
            id=""
            min="0"
            :class="errors['default_rate'] ? 'border border-danger' : ''"
          />
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="">Tax(%)</label>
          <input
            type="number"
            v-model="serviceori.tax_percentage"
            name=""
            class="form-control"
            id=""
            min="0"
          />
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="">Commission($)</label>
          <input
            type="number"
            v-model="serviceori.commission_percentage"
            name=""
            class="form-control"
            id=""
            min="0"
          />
        </div>
      </div> -->
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          @click="close()"
          variant="danger"
          size="sm"
          class="float-right"
        >
          Close
        </b-button>
        <b-button
          ref="kt_save_add_another_company"
          variant="success"
          size="sm"
          class="float-right mr-5"
          @click="store('SAA', 'kt_save_add_another_company')"
          v-if="!is_edit"
        >
          Save and add another
        </b-button>
        <b-button
          ref="kt_save_company"
          v-if="!is_edit"
          @click="store('S', 'kt_save_company')"
          variant="success"
          size="sm"
          class="float-right mr-5"
        >
          Save
        </b-button>
        <b-button
          ref="kt_update_company_data"
          v-if="is_edit"
          @click="store('Update', 'kt_update_company_data')"
          variant="success"
          size="sm"
          class="float-right mr-5"
        >
          Update
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import {} from "@/core/services/store/mutations.type";
import { CREATE_SERVICEORI } from "@/core/services/store/actions.type";
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    is_edit: {
      type: Boolean,
      default: false
    },
    serviceori: {
      type: Object,
      default: () => {
        return {
          ori_number: "",
          id: 0,
          profession: "",
          requires_photograph: false,
          service_code: "",
          our_rate: "",
          errors: []
        };
      }
    }
  },
  data() {
    return {
      showImage: false,
      errors: []
    };
  },

  components: {},
  computed: {
    ...mapGetters(["getServiceCodeData"])
  },

  methods: {
    close() {
      this.$emit("closemodal");
    },
    clearForm() {
      this.serviceori.ori_number = "";
      this.serviceori.id = 0;
      this.serviceori.profession = "";
      this.serviceori.our_rate = "";
    },

    store(saveType, ref) {
      this.$store
        .dispatch(CREATE_SERVICEORI, this.serviceori)
        .then(data => {
          if (!this.is_edit) {
            if (saveType == "S") {
              this.$router.push({ name: "admin.serviceori" });
              this.$toastr.s(data.msg);

              this.$emit("closemodal");
            } else if (saveType == "SAA") {
              this.$toastr.s(data.msg);
              this.clearForm();
            }
          } else {
            this.$emit("closemodal");
            this.$toastr.s("ORI successfully updated");
          }
          this.closeBtnLoad(ref);
        })
        .catch(err => {
          this.errors = err;
          this.closeBtnLoad(ref);
        });
    },
    closeBtnLoad(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
      submitButton.disabled = false;
    }
  }
};
</script>
