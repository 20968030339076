<style>
.swal2-popup .swal2-icon {
  margin-left: 39% !important;
}
</style>
<style lang="scss" scoped>
.card-custom {
  .body {
    .fixed {
      position: fixed;
      width: 77.1rem;
      background-color: #ffffff;
      left: 23.6rem;
      z-index: 1;
      top: 15%;
      .search {
        margin-left: 2% !important;
      }
    }
    .move-below {
      margin-top: 13% !important;
    }
  }
}
</style>
<template>
  <div class="card card-custom">
    <div class="card-body">
      <div class="col-md-12" style="background:white">
        <div class="row">
          <div class="col-md-2">
            <input
              v-model="searchInput"
              type="text"
              placeholder="Search ORI"
              class="form-control"
            />
          </div>
          <div class="col-md-4">
            <select
              class="form-control"
              id="exampleFormControlSelect1"
              v-model="select_service"
            >
              <option value="">Select Service</option>
              <option
                v-for="(item, index) in getServiceCodeData.data"
                :key="index"
                :value="item.id"
              >
                {{ item.service_name }}
              </option>
            </select>
          </div>
          <div class="col-md-2">
            <button class="btn btn-outline-info" @click="search()">
              <i class="fa fa-search"></i>
            </button>
          </div>
          <div class="col-md-2"></div>
          <div class="col-md-2 float-right">
            <button
              class="btn btn-info"
              style="float: right"
              @click="showModal"
            >
              <i class="fa fa-plus"></i> Add ORI
            </button>
          </div>
        </div>
        <div
          class="row mt-5"
          v-if="filteredSerivceOri.length > 0 && !loadingData"
        >
          <div class="col-md-12">
            <v-table :columns="columns">
              <tr v-for="(item, index) in filteredSerivceOri" :key="index">
                <td>{{ ++index }}</td>
                <td>{{ item.ori_number }}</td>
                <td>{{ item.profession }}</td>
                <td class="text-center">{{ item.service_code_name }}</td>
                <td class="text-center">
                  <i
                    class="fa fa-times text-danger"
                    v-if="!item.requires_photograph"
                  ></i>
                  <i
                    class="fa fa-check text-success"
                    v-if="item.requires_photograph"
                  ></i>
                </td>

                <td class="text-center">{{ item.our_rate }}</td>

                <td>
                  <button
                    @click="edit(item)"
                    class="btn btn-sm btn-success mr-3 mt-2"
                    style="cursor: pointer;"
                  >
                    Edit
                  </button>
                  <button
                    @click="deleteItem(item.id)"
                    class="btn btn-sm btn-danger mr-3 mt-2"
                    style="cursor: pointer;"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            </v-table>
          </div>
        </div>
        <div
          class="row  mt-5"
          v-if="filteredSerivceOri.length < 1 && !loadingData"
        >
          <div class="col-md-12 text-center">
            <h4>no data found</h4>
          </div>
        </div>

        <div class="row" v-if="loadingData">
          <div class="col-md-12 text-center">
            <v-data-loader></v-data-loader>
          </div>
        </div>
      </div>
      <serviceori
        v-if="ModalShow"
        v-on:closemodal="closeModal"
        :show-modal="ModalShow"
        :serviceori="serviceOriData"
        :is_edit="is_edit"
      ></serviceori>
    </div>
  </div>
</template>

<script>
// import moment from "moment";
import {
  FETCH_SERVICEORI,
  CREATE_SERVICEORI,
  DELETE_SERVICEORI,
  FETCH_SERVICECODES
} from "@/core/services/store/actions.type";
import vTable from "@/includes/table/Datatable";
import serviceori from "@/pages/backend/servicesori/serviceori";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import { mapGetters } from "vuex";
import vDataLoader from "@/components/frontend/spinner.vue";

export default {
  computed: {
    ...mapGetters(["currentUser", "getServiceOriData", "getServiceCodeData"]),
    filteredSerivceOri() {
      let tempServiceOri = this.getServiceOriData;
      //process search input
      if (this.searchInput != "" && this.searchInput) {
        tempServiceOri = tempServiceOri.data.filter(item => {
          return (
            item.ori_number
              .toUpperCase()
              .includes(this.searchInput.toUpperCase()) ||
            item.profession
              .toUpperCase()
              .includes(this.searchInput.toUpperCase())
          );
        });
      } else {
        tempServiceOri = tempServiceOri.data;
      }
      return tempServiceOri;
    }
  },
  created() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.$store.dispatch(FETCH_SERVICECODES);
    this.getServiceOri();
  },
  components: {
    serviceori,
    vDataLoader,
    vTable
  },
  methods: {
    search() {
      this.searchInput = "";
      this.getServiceOri();
    },
    showModal() {
      this.serviceOriData = {
        id: 0,
        ori_number: "",
        profession: "",
        our_rate: "",
        service_code: ""
      };
      this.ModalShow = true;
      this.is_edit = false;
    },
    closeModal() {
      this.ModalShow = false;
      var data_ = { service_code_id: this.select_service };
      this.$store.dispatch(FETCH_SERVICEORI, data_);
    },
    edit(item) {
      this.ModalShow = true;
      this.is_edit = true;
      this.serviceOriData = {
        ...item
      };
    },
    update(item) {
      let data = { ...item };
      var data_ = { service_code_id: this.select_service };
      this.$store
        .dispatch(CREATE_SERVICEORI, data)
        .then(() => {
          this.$toastr.s("Updated Successfully");
          this.$store.dispatch(FETCH_SERVICEORI, data_);
        })
        .catch(err => {
          this.errors = err;
        });
    },
    getServiceOri() {
      this.loadingData = true;
      var data_ = { service_code_id: this.select_service };
      this.$store
        .dispatch(FETCH_SERVICEORI, data_)
        .then(() => {
          this.loadingData = false;
        })
        .catch(() => {
          this.loadingData = false;
        });
    },
    deleteItem(id) {
      this.$swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover!",
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Yes"
      }).then(willDelete => {
        if (willDelete.isConfirmed) {
          this.$store
            .dispatch(DELETE_SERVICEORI, id)
            .then(() => {
              this.$toastr.s("Deleted Successfully ");
              this.getServiceOri();
            })
            .catch(err => {
              this.errors = err;
            });
        }
      });
    }
  },
  data() {
    return {
      loadingData: false,
      ModalShow: false,
      columns: [
        {
          name: "S No.",
          width: "5%",
          label: "S No.",
          class: ""
        },
        {
          name: "ORI",
          width: "10%",
          label: "ORI",
          class: ""
        },
        {
          name: "Profession",
          width: "",
          label: "Profession",
          class: ""
        },
        {
          name: "Service Code",
          width: "",
          label: "Service Code",
          class: "text-center"
        },
        {
          name: "Requires Photograph",
          width: "",
          label: "Requires Photograph",
          class: "text-center"
        },
        {
          name: "Actual Rate",
          width: "8%",
          label: "Actual Rate",
          class: "text-center"
        },
        {
          name: "Action",
          width: "10%",
          label: "Action",
          class: "text-center"
        }
      ],
      select_service: "",
      searchInput: "",
      is_edit: false,
      serviceOriData: {
        id: 0,
        ori_number: "",
        profession: "",
        our_rate: "",
        service_code: ""
      },
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "ORI List",
          route: ""
        }
      ]
    };
  }
};
</script>
